import React from 'react';
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";

const Leasing = () => {
  return (
    <article className="wrapper">
      <NavBar current="leasing"/>
      <div className="mx-auto lg:text-left lg:w-3/4 mt-8 px-4 md:px-6 lg:px-8 max-w-screen-xl pb-28">
        <div className="cards w-full">
          <h1 className="text-2xl">Leasing</h1>

          <p className="mt-8">
            Immer mehr Privatkunden entscheiden sich für die Finanzierungsform Leasing. Im Folgenden können Sie sich
            darüber
            informieren welche Vorteile dieser Vertragsform für Sie bringt. Verantwortlich für die monatliche Leasingrate
            ist zum einen der Fahrzeugpreis und der vertraglich vereinbarte Restwert. Als Restwert wird der
            Gebrauchtwagenspreis des Leasingsfahrzeuges am Vertragsende bezeichnet. Je höher der Restwert, desto niedriger
            ist die Leasingrate. Die Differenz aus Fahrzeugpreis und Restwert ergibt den zu finanzierenden Betrag. Über
            eine
            Anzahlung kann die monatliche Rate ebenfalls verringert werden. Da die Anzahlung vom Kaufpreis abgezogen wird.
            Ebenfalls können sich Nachlässe des Händlers positiv auf die Höhe der Rate auswirken.
            <br/><br/>
            Es wird generell zwischen zwei verschiedenen Vertragsarten beim Leasing unterschieden. Häufig kommt der
            Kilometer Vertrag bei dem der Leasingnehmer sich für eine Laufzeit und die jährliche Kilometer Laufleistung
            entscheidet zum Einsatz. Der Kilometer Vertrag bringt durch die Tatsache dass es am Ende des Vertrages nur
            durch
            zu viel gefahrenen Kilometer oder Unfallschäden zu einer Mehrleistung kommen kann finanzielle Sicherheit.
            Außerdem Kilometer Vertrag gibt es auch noch den Restwertvertrag. Bei dieser Art des Leasingvertrages
            übernimmt
            der Kunde das volle Restwertrisiko für das geleaste Fahrzeug. Das hat zur Folge dass nach Ablauf der
            Leasingzeit
            das Fahrzeug geschätzt wird, dieser Schätzwert wird dem zu Beginn des Vertrages festgelegt dem Restwert
            gegenübergestellt. Sollte sich aus diesen beiden Werten eine Differenz ergeben hat der Leasingnehmer diese
            Differenz zu begleichen.
          </p>
        </div>
      </div>
      <Footer />
    </article>
  );
};

export default Leasing;